
const deg2rad = function(deg) {
    return deg * (Math.PI/180)
}

const rad2deg = function(deg) {
    return deg * (180/Math.PI)
}

const distanceBetweenPoints = ([p1x, p1y], [p2x, p2y], f = (x) => x) => f(Math.sqrt(
    Math.pow(p1x - p2x, 2) + Math.pow(p1y - p2y, 2)
  ));

const distanceToLine = ([px, py], [[l1x, l1y], [l2x, l2y]], f = (x) => x) => {
  const xD = l2x - l1x;
  const yD = l2y - l1y;

  const u = (((px - l1x) * xD) + ((py - l1y) * yD)) / ((xD * xD) + (yD * yD));

  let closestLine;
  if (u < 0) {
    closestLine = [l1x, l1y];
  } else if (u > 1) {
    closestLine = [l2x, l2y];
  } else {
    closestLine = [l1x + (u * xD), l1y + (u * yD)];
  }

  return f(distanceBetweenPoints([px, py], closestLine));
};


const intersectionPointToLine= ([px, py], [[l1x, l1y], [l2x, l2y]]) => {
    // console.log("------------intersectionPointToLine----------------------" , [px, py], [[l1x, l1y], [l2x, l2y]])
       // Normalisierte Richtung this.p1 --> this.p2 ausrechnen 
       let dx = Number(l2x) - Number(l1x);
       let dy = Number(l2y) - Number(l1y);
       if(dx == 0 && dy == 0)
           return [l1x, l1y]

       let len = Math.sqrt(dx * dx + dy * dy);
       dx /= len;
       dy /= len;

           // Richtung this.p1 --> a ausrechnen
       let dax = px - l1x;
       let day = py - l1y;

       // Punkt a auf Gerade this.p1 --> this.p2 projizieren
       let dot = dax * dx + day * dy;
       let resx = l1x + dx * dot;
       let resy = l1y + dy * dot;
       return [resx, resy]
}

const intersectionPointToLineCoordinate= (point, linePoint1, linePoint2) => {
    // console.log("intersectionPointToLineCoordinate", point, linePoint1, linePoint2)
    const pt = convertCoordinate2Point(point)
    const pl1 = convertCoordinate2Point(linePoint1)
    const pl2 = convertCoordinate2Point(linePoint2)
    return convertPoint2Coordinate( intersectionPointToLine(pt,[pl1,pl2]))
}

const distanceToPolygon = ([px, py], poly, f = (x) => x) => {
    const comp = poly.reduce(
        ({ prevPoint, dist }, currPoint) => {
            const ret = {
                prevPoint: currPoint,
                dist,
            };
            if(prevPoint[0] == currPoint[0] && prevPoint[0] == currPoint[0])
                return ret;
            const currDist = distanceToLine([px, py], [prevPoint, currPoint]);

            if (currDist < dist) {
                ret.dist = currDist;
            }
            return ret;
        }, { prevPoint: poly[poly.length - 1], dist: Infinity });
    return f(comp.dist);
};

const convertCoordinate2Point = (val) => {    
    return JSON.parse(JSON.stringify(val).replace(/({"lat":)\s*((-?\d+.\d+)|(-?\d+))\s*,\s*("lng":)\s*((-?\d+.\d+)|(-?\d+))\s*(})/gi, '[$6,$2]' ))
}

const convertPoint2Coordinate = (val) => {
    return JSON.parse(JSON.stringify(val).replace(/(\[)\s*((-?\d+.\d+)|(-?\d+))\s*,\s*((-?\d+.\d+)|(-?\d+))\s*(\])/gi, '{"lat":$5,"lng":$2}' ))
}

/**
* 
* @param {{lat: number, lng: number}} point - point in coordinates
* @param {Array.<[[x,y]]>}  poly - Array of points from polygon.
* @callback cb x distance to polygon in km
*/
const distanceToPolygonCoordinate = (point, poly, f = (x) => x) => {
    // console.log("distanceToPolygonCoordinate" , point)
    const pt = convertCoordinate2Point(point)
    const pl = convertCoordinate2Point(poly)
    
    const comp = pl.reduce(
        ({ prevPoint, dist }, currPoint) => {
            const ret = {
                prevPoint: currPoint,
                dist,
            };
            if(prevPoint[0] == currPoint[0] && prevPoint[0] == currPoint[0])
                return ret;            
            const isPt = intersectionPointToLine(pt, [prevPoint, currPoint])
            const currDist = distancePoint2PointCoordinate({lat:isPt[0], lng:isPt[1]}, {lat:currPoint[0],lng:currPoint[1]});
            if (currDist < dist) {
                ret.dist = currDist;
            }
            return ret;
        }, { prevPoint: pl[pl.length - 1], dist: Infinity });
    return f(comp.dist);
};
  
/**
* 
* @param {{lat: number, lng: number}} point - point in coordinates
* @param {Array.<[[x,y]]>}  poly - Array of points from polygon.
* @callback cb x in polygon
*/
const inPolygon = (point, poly, f = (x) => x) => {
    console.debug("inPolygon")
    const pt = convertCoordinate2Point(point)
    const pl = convertCoordinate2Point(poly)
    
    // const ret = {
    //     prevPoint: currPoint,
    //     count,
    // };
    let count = 0
    const comp = pl.reduce(
        ( {prevPoint, count}, currPoint) => {          
            //console.debug("prevPoint", prevPoint, currPoint)
            if(
                //point.y < prevPoint.y != point.y < currPoint.y
                pt[1] < prevPoint[1] != pt[1] < currPoint[1] &&
                pt[0] < (currPoint[0]-prevPoint[0]) * (pt[1]-prevPoint[1]) / (currPoint[1]-prevPoint[1]) + prevPoint[0]
            ) 
            {
                count++;
            }
            const ret = {
                prevPoint: currPoint,
                count: count,
            };
            return ret;
        }, { prevPoint: pl[pl.length - 1], count });
       
    const res =  comp.count % 2 == 0 ? false : true    
    return f(res);
}

/**
 * 
 * @param {{lat: number, lng: number}} point1 - point in coordinates
 * @param {{lat: number, lng: number}} point1 - point in coordinates
 * @return {number} distance in km
 */
const distancePoint2PointCoordinate = (point1, point2) =>{
    const lat1 = point1.lat
    const lng1 = point1.lng
    const lat2 = point2.lat
    const lng2 = point2.lng

    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dlng = deg2rad(lng2-lng1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dlng/2) * Math.sin(dlng/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}

const angle = ([[l1x, l1y], [l2x, l2y]]) => {

    if(l1y == l2y)
        return 0     
    else {
        var tmp = (l2x - l1x)/(l2y-l1y);
        // console.log(tmp)
        var angle = Math.atan(tmp);
        return  rad2deg(angle);
    }

}

const angleCoordinate = (point1, point2) => {
    const pt1 = convertCoordinate2Point(point1)
    const pt2 = convertCoordinate2Point(point2)

    return angle([pt1,pt2])

}

const douglasPeuckerCoordinates = (points, tolerance) =>{
    if ( points.length <= 2 ) {
        return [points[0]];
    }
    let returnPoints = []
    let maxDistance = 0
    let maxDistanceIndex = 0

    // get first and last point
    const ptFirst = points[0].pt
    const ptLast = points[points.length - 1].pt
    // console.log("-------ptFirst------", ptFirst)
    // console.log("-------ptLast------", ptLast)
    for (let i = 0; i < points.length - 2; i++) {
 
        const intesPt = intersectionPointToLineCoordinate(points[i].pt, ptFirst, ptLast)
        // console.log("-------intesPt------", intesPt)
        const dist = distancePoint2PointCoordinate(intesPt, points[i].pt)*1000 // scale in m
        // console.log("-------dist------", dist)
        if(dist>maxDistance){
            maxDistance = dist
            maxDistanceIndex = i
        }
        
    }
    // check if the max distance is greater than our tollerance allows 
    if ( maxDistance >= tolerance ) {
        // include this point in the output 
        returnPoints = returnPoints.concat( douglasPeuckerCoordinates( points.slice( 0, maxDistanceIndex + 1 ), tolerance ) );
        // returnPoints.push( points[maxDistanceIndex] );
        returnPoints = returnPoints.concat( douglasPeuckerCoordinates( points.slice( maxDistanceIndex, points.length ), tolerance ) );
    } else {
        returnPoints = [points[0]];
    }
    return returnPoints;    

}


const simplifyPathCoordinates = ( points, tolerance, toleranceangle) => {


	const arr = douglasPeuckerCoordinates( points, tolerance );

    // check angle
    for( let i = 1; i <= points.length - 2; i++ ) {
        if(toleranceangle < Math.abs(points[i].angleDiff2last)){
            let found = false
            for (const item of arr) {
                if(item.pt === points[i].pt ){
                    found = true
                }
            }
            if(!found)
                arr.push(points[i])
        }
    }
	// always have to push the very last point on so it doesn't get left off
	arr.push( points[points.length - 1 ] );
	return arr;

}



const out = {
    deg2rad: deg2rad,
    rad2deg: rad2deg,
    distanceBetweenPoints: distanceBetweenPoints,
    distanceToLine:distanceToLine,
    distanceToPolygon: distanceToPolygon,
    distanceToPolygonCoordinate:distanceToPolygonCoordinate,
    convertCoordinate2Point:convertCoordinate2Point,
    convertPoint2Coordinate:convertPoint2Coordinate,
    distancePoint2PointCoordinate:distancePoint2PointCoordinate,
    douglasPeuckerCoordinates:douglasPeuckerCoordinates,
    simplifyPathCoordinates:simplifyPathCoordinates,
    inPolygon:inPolygon,
    angle: angle,
    angleCoordinate:angleCoordinate
};
  
module.exports = out;

