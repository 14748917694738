<template>
    <v-card
        class="mb-12"
        color="grey lighten-1"
        height="500px"
        >


        <v-toolbar dense>
        <v-text-field
            v-model="txtSearchPos" 
            dense
            label="Suchen"
            class="mt-5"
            @change="searchPos"
        ></v-text-field>
        <v-btn 
            @click="searchPos"
            icon>
            <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-spacer></v-spacer>        
       <v-btn 
            :disabled="polyAvailable" 
            @click="setDrawingModePoly"
            :color="btnPolyColor"
            icon>
            <v-icon>mdi-shape-polygon-plus</v-icon>
        </v-btn>
        <v-btn
            :disabled="!polyAvailable" 
            color="grey darken-1"
            @click="deletePoly"
            icon>
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn 
            :disabled="!btnHoleEnabled" 
            @click="setDrawingModePoly"
            :color="btnPolyHoleColor"
            icon>
            <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
        <v-btn 
            :disabled="!polyHoleAvailable" 
            @click="deletePolyHole"
            :color="btnPolyHoleColor"
            icon>
            <v-icon>mdi-delete-circle-outline</v-icon>
        </v-btn>        
         <v-btn 
            @click="setDrawingMode(null)"
            :color="btnHandColor"
            icon>
            <v-icon>mdi-hand-back-right</v-icon>
        </v-btn>

        </v-toolbar>
        <v-alert type="error" v-if="errMessage != ''">
            {{errMessage}}
        </v-alert>
        <gmap-map ref="gmap" :center="mapOptions.center"
            :zoom="zoom"
            mapTypeId= 'hybrid'
                :options="{
                    scrollwheel: mapOptions.scrollwheel,
                    zoomControl: mapOptions.zoomControl,
                    mapTypeControl: mapOptions.mapTypeControl,
                    streetViewControl: false,
                    scaleControl: mapOptions.scaleControl,
                    fullscreenControl: mapOptions.fullscreenControl
                }"
            style="width: 100%; height: 475px"
        >

            <gmap-polygon 
                :paths="mapPath"                 
                :options="polyOptions"
                @paths_changed="updateEdited($event, item.id)"
                ref="polygon">
            </gmap-polygon>

        </gmap-map>                    
    </v-card>   
    
</template>

<script>
import { getGoogleMapsAPI  } from 'gmap-vue';
const geoMath = require('../../../helper/geo-math')

export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    area_ha_calculated: true,
                    area_ha: null,
                    polygon:[],   
                }
            }
        },
        state: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    state:{
                            valid:false
                        }
                    // isEdit: false
                }
            }
        }
    },

    data() {
        return {
            // valid: false,  
            mapOptions:{
                center: {lat:  47.997791, lng: 7.842609},
                scrollwheel: true,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                fullscreenControl: true,
            },
            polyOptions:{
                        editable: true,
                        fillColor: 'red',
                        fillOpacity: 0.3,
                        strokeColor: 'red',
                        strokeOpacity: 0.8,
                        strokeWeight: 3
            },
            holeOptions:{
                        editable: true,
                        fillColor: 'grey',
                        fillOpacity: 0.6,
                        strokeColor: 'grey',
                        strokeOpacity: 0.8,
                        strokeWeight: 3
            },            
            txtSearchPos: '',
            calcArea: '',
            errMessage:'',
            cancelDrawingShape:false,
            drawingMode:null
                   
        }

    },

    computed:{   

        google: getGoogleMapsAPI,

        valid(){
            console.debug("valid", this.polygon)
            return this.polygon == undefined ? false : this.polygon.length > 0 
        },

        zoom(){
            return  this.polyAvailable ? 17 : 17;
        },

        polyAvailable(){
            return this.item.polygon ? this.item.polygon.length > 0 : false;
        },


        polyHoleAvailable(){
            return this.item.polygon ? this.item.polygon.length > 1 : false;
        },

        area_ha_calculated(){
            return this.item.area_ha_calculated
        },

        btnHandColor(){
            return this.drawingMode != "polygon" ? "primary" : "grey darken-1"
        },

        btnPolyColor(){
            return this.drawingMode == "polygon" && !this.polyAvailable ? "primary" : "grey darken-1"
        },

        btnPolyHoleColor(){
            return this.drawingMode == "polygon" && this.polyAvailable ? "primary" : "grey darken-1"
        },
        
        btnHoleEnabled(){
            return this.item.polygon ? this.item.polygon.length == 1 : false;
        },

        mapPath(){           
            const res =  [this.polyAvailable ? this.item.polygon[0]:[], !this.polyAvailable || this.item.polygon.length < 2 ? [] : this.item.polygon[1]]
            return res
        }

    },

    methods:{
        initDrawingManager(){
            if (this.$refs.gmap.$mapObject) {
                //let vm = this;
                if(this.drawingManager)
                    return;

                this.drawingManager = new this.google.maps.drawing.DrawingManager({
                    drawingControl: false,
                    polygonOptions: this.polyOptions
                });
                this.drawingManager.setMap(this.$refs.gmap.$mapObject);
                //const root = this;
                this.drawingManager.addListener('polygoncomplete', this.addPolygon);  
                this.drawingManager.addListener('drawingmode_changed', this.drawingmode_changed);  
                 
            }
        },



        showDrawingCtrl(){
            console.debug('showDrawingCtrl')
            this.stepForward(); 
            this.initDrawingManager();

        },

        updateEdited(mvcPaths, id) {
            console.debug('updateEdited', mvcPaths, id);
            this.item.polygon = this.polygonPaths(mvcPaths);
            this.updateCalcArea();

        },

        deletePoly(){
            this.item.polygon = [];
            this.calcArea = '';
            this.updateCalcArea();
        },

        deletePolyHole(){
            this.item.polygon = this.item.polygon.slice(0,1);
            this.calcArea = '';
            this.updateCalcArea();
        },

        drawingmode_changed(){
            console.debug('drawingmode_changed')
            this.drawingMode =  this.drawingManager ? this.drawingManager.getDrawingMode() : null
        },

        addPolygon(polygon){
            console.debug('addPolygon', polygon)
            if(this.cancelDrawingShape){
                this.cancelDrawingShape = false;
                polygon.getPath().clear();
                return
            }

            if (polygon.getPath().getLength() < 3) {
                this.errMessage = "Polygons must have 3 or more points."
                setTimeout(() => {
                    this.errMessage = ''
                }, 2500);
                
                polygon.getPath().clear();
                return
            }
            
            const poly = this.convertMapPoly(polygon)        
            if(this.item.polygon.length == 1){

                if(!this.isInPolygon(this.item.polygon[0], poly)){  this.errMessage = "Hole must have be in filed borders."
                    setTimeout(() => {
                        this.errMessage = ''
                    }, 2500);
                    
                    polygon.getPath().clear();
                    return
                }

                const isPolyCounterClockwise = (this.google.maps.geometry.spherical.computeSignedArea(this.$refs.polygon.paths[0]) < 0);
                const isHoleCounterClockwise = (this.google.maps.geometry.spherical.computeSignedArea(polygon.getPath()) < 0);
                if(isPolyCounterClockwise != isHoleCounterClockwise)
                    this.item.polygon.push(poly);
                else                    
                    this.item.polygon.push(poly.reverse());
            }
            else{
                this.item.polygon = [];
                this.item.polygon.push(poly);
            }

 
            this.updateCalcArea();
            polygon.setMap(null);
            this.setDrawingMode(null);
                
           

        },

        setDrawingModePoly(){
            console.debug('setDrawingModePoly');
            this.setDrawingMode(this.google.maps.drawing.OverlayType.POLYGON);
        },

        setDrawingMode(mode){
            console.debug('setDrawingMode', this.drawingManager);
            this.cancelDrawingShape = mode == null

            if(this.drawingManager){
                this.drawingManager.setDrawingMode(mode); 
                if(mode == "polygon")
                    this.drawingManager.polygonOptions = this.polyAvailable ? this.holeOptions : this.polyOptions   
                return
            }

            this.initDrawingManager();
            setTimeout(() => {
                console.debug('setDrawingMode1', this.drawingManager);
                this.drawingManager.setDrawingMode(mode);
                if(mode == "polygon")
                    this.drawingManager.polygonOptions = this.polyAvailable ? this.holeOptions : this.polyOptions   

            }, 300);
 

   
        },

        searchPos(){
            //console.debug('searchPos', value);
            console.debug('searchPos', this.txtSearchPos);
            if(this.txtSearchPos == "")
                return;

            let geocoder = new this.google.maps.Geocoder();
            let address = {
                address: this.txtSearchPos
            };
            this.$refs.gmap.$mapObject.setCenter(this.mapOptions.center);
            geocoder
                .geocode(address)
                .then((result) => {
                    const { results } = result;
                    console.debug(results)  
                    this.mapOptions.center = results[0].geometry.location;

                })
                .catch((e) => {
                    alert("Geocode was not successful for the following reason: " + e);
                });


        },

        polygonPaths: function (mvcPaths) {
            console.debug('polygonPaths', mvcPaths)
            if (!mvcPaths) return null

            let paths = [];
            for (let i=0; i < mvcPaths.getLength(); i++) {
                let path = [];
                for (let j=0; j<mvcPaths.getAt(i).getLength(); j++) {
                    let point = mvcPaths.getAt(i).getAt(j);
                    path.push({lat: point.lat(), lng: point.lng()});
                }
                //path.push(path[0]);
                paths.push(path);
                
            }
            
            console.debug('polygonPaths', paths)
            return paths
        },

        updateCalcArea(){
            let areaSize = 0
            if(this.item.polygon.length > 0){
                let area = new this.google.maps.MVCArray(this.item.polygon[0])
                areaSize = this.google.maps.geometry.spherical.computeArea(area) / 10000   
                
                if(this.item.polygon.length > 1){
                    area = new this.google.maps.MVCArray(this.item.polygon[1])
                    areaSize -= this.google.maps.geometry.spherical.computeArea(area) / 10000 
                }
            }

            this.calcArea = areaSize.toFixed(4);     
            if(this.item.area_ha_calculated){
                this.item.area_ha = this.calcArea;
                console.debug('area', this.item.area_ha)
            }

            return this.calcArea

        },

        isInPolygon(polygon, hole){
            console.debug('polygonPaths', polygon, hole)            
            for (let item of hole) {
                if(!geoMath.inPolygon(item, polygon)){
                    return false
                }
            }
            return true           
        },

        convertMapPoly(polygon){
            const poly = [] 
            for (let item of polygon.getPath().getArray()) {
                if (typeof item.lat === 'function')
                    poly.push({lat:item.lat(), lng:item.lng()})
            }
            return poly
        },
 
    },

    watch: { 
  
        item: function(newVal) { // watch it
            console.log('Prop changed: item', newVal)
            this.setDrawingMode(null);
            if(this.item.center)
                this.mapOptions.center = this.item.center;
        },
 
        valid: function(newVal) { // watch it
            console.log('Prop changed: valid', newVal)
            this.state.valid = this.valid; 
        }, 

        area_ha_calculated: function(newVal) { // watch it
            console.log('Prop changed: area_ha_calculated', newVal)
            if(newVal)
                this.updateCalcArea()
        }, 
    },

    mounted() {
        console.debug('mounted', this.item);
        if(this.item.center)
                this.mapOptions.center = this.item.center;

        
    }
}
</script>
